import * as cssVendor from 'css-vendor';

const supportedCSSValue = cssVendor.supportedValue;

export const jsprefix = x => `${cssVendor.prefix.js}${x}`;

export const cssprefix = x => `${cssVendor.prefix.css}${x}`;

export const cssvalue = (prop, value) =>
  supportedCSSValue(prop, value) || cssprefix(value);

export const coreStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  display: cssvalue('display', 'flex'),
};

export const faces = {
  above: 'down',
  right: 'left',
  below: 'up',
  left: 'right',
};

/* Flow mappings. Each map maps the flow domain to another domain. */

export const flowToTipTranslations = {
  row: 'translateY',
  column: 'translateX',
};
