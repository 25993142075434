import React from 'react';
import { FunctionComponent, useContext } from 'react';

import { useToasts } from 'shared/components/toast/use-toasts.hook';
import { useHeaderHeight } from 'app/hooks/use-header-height.hook';
import { ToastComponentContext } from 'shared/components/toast/toast-component-context';
import { usePageHeight } from 'app/hooks/use-page-height.hook';

import './toast-container.component.scss';

export const ToastContainer: FunctionComponent = () => {
  const toasts = useToasts();
  const topOffset = useHeaderHeight();
  const pageHeight = usePageHeight();
  const ToastComponent = useContext(ToastComponentContext);

  return (
    <div className="toasts-container" style={{top: `${topOffset}px`, maxHeight: `${pageHeight - topOffset - 20}px`}}>
      {toasts.map(toast => <ToastComponent key={toast.id} {...toast}/>)}
    </div>
  );
};
