import { useDispatch } from 'react-redux';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { ToastOptions } from './toast-interfaces';
import { useOnce } from 'app/hooks/use-once.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';

export const useToastManager = (): ToastManager => {
  const dispatch = useDispatch();
  return useOnce<ToastManager>(() => new ToastManager(dispatch));
};

export const useToasts = (): ToastOptions[] => useSelector(state => state.toasts);
