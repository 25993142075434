import { useCallback, useEffect, useState } from 'react';

export const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);

  const measureHeaderHeight = useCallback(() => {
    const header = document.querySelector('.header-component');
    setHeaderHeight(header ? header.clientHeight : 0);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', measureHeaderHeight);
    const interval = setInterval(measureHeaderHeight, 500);
    measureHeaderHeight();

    return () => { window.removeEventListener('resize', measureHeaderHeight); clearInterval(interval); };
  }, []);

  return headerHeight;
};
