import React, { ReactNode, useContext, useEffect } from 'react';
import classNames from 'classnames';

import { usePageInfoAnalytics } from 'app/hooks/analytics/use-page-info-analytics.hook';
import { SiteSectionContext } from 'shared/components/analytics/site-section.context';
import { PageContext } from 'shared/components/analytics/page.context';
import { NavCarrierToastContainer } from 'app/toast-container.component';

import './page.component.scss';

interface Props {
  name: string;
  className?: string;
  plain?: boolean;
  children?: ReactNode;
}

export const Page: React.FC<Props> = ({ name, plain, className, ...props }) => {
  const { section } = useContext(SiteSectionContext);
  usePageInfoAnalytics(name, section);
  useMedalliaPageView();
  const id = name.toLowerCase().replace(/\s/g, '-') + '-page';
  return (
    <PageContext.Provider value={{ page: name }}>
      <div className={classNames((plain ? null : 'page-component'), className)} id={id}>
        <NavCarrierToastContainer />
        {props.children}
      </div>
    </PageContext.Provider>
  );
};

const useMedalliaPageView = () => {
  useEffect(() => {
    if (window['KAMPYLE_ONSITE_SDK'] && typeof window['KAMPYLE_ONSITE_SDK'].updatePageView === 'function') {
      window['KAMPYLE_ONSITE_SDK'].updatePageView();
    }
  }, []);
};
