import React from 'react';
import { useMemo } from 'react';

import { useResources } from 'app/hooks/store/use-resources.hook';
import { ToastContainer } from 'shared/components/toast/toast-container.component';
import { ToastResourceContext } from 'shared/components/toast/toast-resource-context';

export const NavCarrierToastContainer = () => {
  const resources = useResources();
  const context = useMemo(() => ({
    success: resources.SUCCESS,
    error: resources.ERROR,
    warning: resources.WARNING,
    info: resources.INFO,
    dismiss: resources.DISMISS
  }), [resources]);

  return (
    <ToastResourceContext.Provider value={context}>
      <ToastContainer/>
    </ToastResourceContext.Provider>
  );
};
