import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, ReactElement } from 'react';

import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { DismissToastButton } from 'shared/components/toast/dismiss-toast-button.component';

import './toast.component.scss';

interface Props {
  id: string;
  className?: string;
  body?: ReactElement<any> | string;
  buttons?: (ReactElement<any> | ((id: string) => ReactElement<any>))[];
}

export const Toast: FunctionComponent<Props> = ({ id, className, body, buttons = [] }) => {
  return (
    <div key={id} className={classNames('toast-container', className)}>
      <ErrorBoundary>
        <div role="alert">
          <div className="toast-content">
            {body}
          </div>
          <div className="button-bay">
            {(Boolean(buttons) && Boolean(buttons.length))
              && buttons.map((button, index) => {
                return typeof button === 'function'
                  ? <React.Fragment key={index}>{button(id)}</React.Fragment>
                  : <React.Fragment key={index}>{button}</React.Fragment>;
              })}
            <React.Fragment>
              <DismissToastButton id={id} />
            </React.Fragment>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};
