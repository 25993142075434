import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'shared/components/elements/button.component';
import { Translation } from 'shared/components/translation/translation.component';
import { dismissToast } from './toast-interfaces';

interface DismissProps {
  id: string;
}

export const DismissToastButton: FunctionComponent<DismissProps> = ({ id }) => {
  const dispatch = useDispatch();
  const dismissFunc = () => dispatch(dismissToast(id));
  return (
    <Button
      id={`btn-dismiss-toast-${id}`}
      key={`dismiss-${id}`}
      track btnLink type="button"
      onClick={dismissFunc}>
      <Translation resource="DISMISS" />
    </Button>
  );
};
