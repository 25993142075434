export const find = (f, xs) => xs.reduce((b, x) => (b ? b : f(x) ? x : null), null);

export const equalRecords = (o1, o2) => {
  for (const key in o1) {
    if (o1[key] !== o2[key]) {
      return false;
    }
  }
  return true;
};

export const noop = () => undefined;
