import { useMemo } from 'react';
import { Cookie } from 'ng2-cookies';
import { Container } from 'typedi';
import { useAnalyticsEvent, pageLoadCompletedEvent, pageInitializedEvent, pageInfoEvent } from '@chr/react-analytics';

import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { Culture } from 'app/i18n/culture.actions';

export const usePageInfoAnalytics = (pageName: string, section: string) => {
  const user = useStoredUser();
  const culture = useSelector<Culture>(state => state.culture);
  const region = Cookie.get('region') || (user?.getCarrier()?.regionCode);
  const siteLanguage = (culture?.locale?.locale) || (user?.properties.culture) || 'en-US';
  const buildVersion = Container.get<string>('appConstants.buildNumber');

  const pageData = useMemo(() => ({
    pageName: pageName,
    canonicalURL: window.location.href,
    siteLanguage,
    region,
    siteName: 'Navisphere Carrier',
    siteSection: section,
    siteBuildVersion: buildVersion
  }), [pageName, siteLanguage, region]);

  const initEvent = useMemo(() => pageInitializedEvent(), []);
  const infoEvent = useMemo(() => pageData && pageInfoEvent(pageData), [pageData]);
  const completeEvent = useMemo(() => pageLoadCompletedEvent(), []);

  useAnalyticsEvent(initEvent);
  useAnalyticsEvent(infoEvent);
  useAnalyticsEvent(completeEvent);
};
