import { useCallback, useEffect, useState } from 'react';

export const usePageHeight = () => {
  const [bodyHeight, setBodyHeight] = useState(0);

  const measureHeaderHeight = useCallback(() => {
    const body = window.innerHeight;
    setBodyHeight(body ? body : 0);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', measureHeaderHeight);
    const interval = setInterval(measureHeaderHeight, 500);
    measureHeaderHeight();

    return () => { window.removeEventListener('resize', measureHeaderHeight); clearInterval(interval); };
  }, []);

  return bodyHeight;
};
